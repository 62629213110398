import { IconName } from '../Icon';
import IconButton, { IconButtonProps } from '../IconButton';

export type UnstyledAccordionItemCaretProps = {
  isActive?: boolean;
  isAccordionItem?: boolean;
  icon?: IconName;
  IconProps?: Omit<IconButtonProps, 'icon'>;
};

const UnstyledAccordionItemCaret = ({
  icon = 'chevronUp',
  IconProps,
  isAccordionItem = true,
  isActive,
}: UnstyledAccordionItemCaretProps) => {
  return (
    isAccordionItem && (
      <IconButton
        color="blue"
        size="large"
        style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }}
        icon={icon}
        {...IconProps}
      />
    )
  );
};

export default UnstyledAccordionItemCaret;
