'use client';

import { mergePropsClassName } from '@liquorice/utils';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './UnstyledAccordion.css';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';

export type UnstyledAccordionItemHandleProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  { index: number; isAccordionItem?: boolean }
>;

const UnstyledAccordionItemHandle = ({
  children,
  index,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAccordionItem,
  cx,
  ...props
}: UnstyledAccordionItemHandleProps) => {
  const { toggle } = useUnstyledAccordionItem(index);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    props?.onClick?.(ev);
    toggle();
  };

  return (
    <Box
      cx={{ pY: 'xs', ...cx }}
      {...mergePropsClassName(props, styles.control())}
      role="button"
      onClick={handleClick}>
      {children}
    </Box>
  );
};

export default UnstyledAccordionItemHandle;
