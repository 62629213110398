import { RefObject, useEffect, useLayoutEffect, useRef } from 'react';

function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void
): void;
function useEventListener<
  K extends keyof HTMLElementEventMap,
  T extends HTMLElement = HTMLDivElement
>(eventName: K, handler: (event: HTMLElementEventMap[K]) => void, element: RefObject<T>): void;

function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | void = void
>(
  eventName: KW | KH,
  handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
  element?: RefObject<T>
) {
  const lastestHandler = useRef(handler);

  useLayoutEffect(() => {
    lastestHandler.current = handler;
  });

  useEffect(() => {
    // Define the listening target
    const targetElement = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    const newHandler = (e: Event) => lastestHandler.current(e);

    targetElement.addEventListener(eventName, newHandler);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, newHandler);
    };
  }, [eventName, element]);
}

export default useEventListener;
