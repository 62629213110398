import { RefObject, useEffect, useState } from 'react';
import useEventListener from './useEventListener';

interface Size {
  width: number;
  height: number;
}

function useElementSize<T extends HTMLElement>(
  ref: RefObject<T>,
  height: number = 0,
  width: number = 0
): Size {
  // console.log("custom hook run");
  const [size, setSize] = useState<Size>({
    width,
    height,
  });

  const handleSize = () => {
    const newWidth = ref.current?.offsetWidth;
    const newHeight = ref.current?.offsetHeight;

    if (size.width !== newWidth || size.height !== newHeight)
      setSize({
        width: newWidth ?? width,
        height: newHeight ?? height,
      });
  };

  useEventListener('resize', debounce(handleSize, 0)); // Optional debounce time prop if needed

  useEffect(() => {
    handleSize();
  });

  return size;
}

function debounce<Callback extends (...args: Array<unknown>) => void>(fn: Callback, delay: number) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export default useElementSize;
