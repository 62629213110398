import { Color } from '@/styles/partials/palette.css';
import { create } from 'zustand';

export type NavBarResponsiveVariant = 'mobile' | 'desktop';

export type NavBar = {
  fallbackHeight: number;
  height?: number | undefined;
  setHeight: (height: number | undefined) => void;

  menu: boolean;
  menuTheme?: Color;
  menuId?: string;
  setMenu: (menu: boolean, menuTheme?: Color, menuId?: string) => void;

  drawer: boolean;
  drawerTheme?: Color;
  setDrawer: (drawer: boolean, drawerTheme?: Color) => void;
};

export const useNavBarActions = create<NavBar>()((set) => ({
  fallbackHeight: 100,
  height: undefined,
  setHeight: (height?: number) => set(() => ({ height })),

  menu: false,
  setMenu: (menu: boolean, menuTheme?: Color, menuId?: string) =>
    set(() => ({ menu, menuTheme, menuId })),

  drawer: false,
  setDrawer: (drawer: boolean, drawerTheme?: Color) => set(() => ({ drawer, drawerTheme })),
}));
