'use client';

import useElementSize from '@/lib/utils/useElementSize';
import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useEffect, useRef } from 'react';
import HideOnScroll from '../HideOnScroll';
import { BoxProps } from '../ui/Box';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import * as styles from './NavBar.css';
import NavBarShadow from './NavBar.Shadow';
import NavBarShim from './NavBar.Shim';
import { useNavBarActions } from './useNavBar';

export type NavBarInnerProps = BoxProps;

export default function NavBarInner({
  children,
  colorSet: inheritedColorSet,
  ...props
}: NavBarInnerProps) {
  const { fallbackHeight, setHeight, menuTheme } = useNavBarActions();

  const colorSet = menuTheme ?? inheritedColorSet;

  const ref = useRef<HTMLDivElement>(null);
  const { height } = useElementSize(ref, fallbackHeight);

  useEffect(() => {
    setHeight(height);
  }, [height, setHeight]);

  return (
    <>
      <NavBarShadow />
      <NavBarShim />
      <HideOnScroll>
        <Flex
          as="nav"
          colorSet={colorSet}
          paper
          style={{ ...assignInlineVars({ [styles.navbarHeight]: `${height}px` }) }}
          {...mergePropsClassName(props, styles.root)}>
          <Container ref={ref} cx={{ pT: { xs: 'xs', lg: 'xl' }, pB: { xs: 'xs', lg: 'md' } }}>
            {children}
          </Container>
        </Flex>
      </HideOnScroll>
    </>
  );
}
