'use client';

import { motion } from 'framer-motion';
import Box from '../ui/Box';
import * as styles from './NavBar.css';
import { useNavBarActions } from './useNavBar';

export default function NavBarShadow() {
  const { menu } = useNavBarActions();

  return <Box as={motion.div} className={styles.navListShim({ active: menu })} />;
}
