'use client';

import { NavMenu } from '@/lib/parsers/nav';
import { useBreakpointMin } from '@/lib/utils/useBreakpoints';
import { ColorSetVariants } from '@/styles/partials/palette.css';
import IconButton from '../IconButton';
import Logo from '../Logo';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import * as styles from './NavBar.css';
import NavBarCtaNav from './NavBar.CtaNav';
import NavBarDrawer from './NavBar.Drawer';
import NavBarLoginNav from './NavBar.LoginNav';
import NavBarPrimaryNav from './NavBar.PrimaryNav';
import { useNavBarActions } from './useNavBar';

export type NavBarMenuProps = {
  primaryNav?: NavMenu;
  loginNav?: NavMenu;
  ctaNav?: NavMenu;
  colorSet?: keyof ColorSetVariants;
};

export default function NavBarMenu({ primaryNav, loginNav, ctaNav, colorSet }: NavBarMenuProps) {
  const { drawer, setDrawer } = useNavBarActions();
  const lg = useBreakpointMin('lg');

  return (
    <>
      <Flex as="ul" direction="row" alignItems="center" columnGap="xl" cx={{ listStyle: 'none' }}>
        <Box className={styles.logo}>
          <Logo homeLink />
        </Box>
        {lg && <NavBarPrimaryNav colorSet={colorSet} primaryNav={primaryNav} />}
      </Flex>
      <Flex
        as="ul"
        direction="row"
        alignItems="center"
        cx={{ listStyle: 'none', columnGap: { xs: '2xs', md: 'sm' } }}>
        {lg && <NavBarLoginNav loginNav={loginNav} colorSet={colorSet} />}
        <NavBarCtaNav ctaNav={ctaNav} />
        {!lg && (
          <IconButton color="blue" size="large" icon="menu" onClick={() => setDrawer(!drawer)} />
        )}
      </Flex>
      <NavBarDrawer NavBarMenuProps={{ primaryNav, loginNav, ctaNav, colorSet }} />
    </>
  );
}
