/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import UnstyledAccordion from '@/components/UnstyledAccordion';
import { Color } from '@/styles/partials/palette.css';
import { Drawer } from '@mui/material';
import React from 'react';
import IconButton from '../IconButton';
import NavList from '../NavList';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './NavBar.css';
import { NavBarMenuProps } from './NavBar.Menu';
import { useNavBarActions } from './useNavBar';

type NavBarDrawerProps = FlexProps<
  'div',
  {
    NavBarMenuProps?: NavBarMenuProps;
  }
>;

export default function NavBarDrawer({ NavBarMenuProps, ...props }: NavBarDrawerProps) {
  const { primaryNav, loginNav, ctaNav, colorSet: inheritedColorSet } = NavBarMenuProps ?? {};
  const { drawer, setDrawer, drawerTheme } = useNavBarActions();
  const [primaryNavActive, setPrimaryNavActive] = React.useState(false);
  const [loginNavActive, setLoginNavActive] = React.useState(false);

  const colorSet = drawerTheme;

  const handleClose = () => {
    setPrimaryNavActive(false);
    setLoginNavActive(false);
    setDrawer(false, undefined);
  };

  const handleClick = (open: boolean, color?: Color) => {
    setDrawer(open, !primaryNavActive ? color : undefined);
    setPrimaryNavActive(!primaryNavActive);
  };

  return (
    <>
      <Drawer
        open={drawer}
        anchor="right"
        elevation={0}
        onClose={handleClose}
        PaperProps={{
          className: styles.drawer,
        }}>
        <Flex
          cx={{ height: 'screen', width: 'screen', transition: 'colors' }}
          colorSet={primaryNavActive || loginNavActive ? colorSet : 'paper'}
          paper
          {...props}>
          <Flex
            as="ul"
            direction="row"
            justifyContent="end"
            cx={{ pT: 'xs', pB: 'xs', pX: 'margin' }}>
            <Box>
              <IconButton color="blue" icon="close" onClick={handleClose} size="large" />
            </Box>
          </Flex>
          <UnstyledAccordion>
            {primaryNav?.items?.map(
              ({ type, subItems, newWindow, external, parentId, ...rest }, index) => {
                return (
                  <UnstyledAccordion.Item
                    key={rest.id}
                    {...{
                      isAccordionItem: (subItems?.length ?? 0) > 0,
                      index,
                      HandleProps: {
                        cx: { p: 'margin' },
                        onClick: () =>
                          handleClick((subItems?.length ?? 0) > 0 ? true : false, rest.colorSet),
                      },
                      style: { border: 'none' },
                      LabelProps: {
                        children: rest.href ? (
                          <Btn variant="nav" onClick={handleClose} {...rest}>
                            <Txt as="h6" variant="h6" noMargin>
                              {rest.children}
                            </Txt>
                          </Btn>
                        ) : (
                          <Txt as="h6" variant="h6" noMargin>
                            {rest.children}
                          </Txt>
                        ),
                      },
                      children: (
                        <Flex cx={{ pX: 'margin' }} spacing="xs" alignItems="start">
                          <NavList direction="column" spacing="2xs" items={subItems} />
                        </Flex>
                      ),
                    }}
                  />
                );
              }
            )}
          </UnstyledAccordion>
          <UnstyledAccordion BoxProps={{ cx: { mT: 'auto' } }}>
            {loginNav?.items?.map(
              ({ type, subItems, newWindow, external, parentId, ...rest }, index) => {
                return (
                  <UnstyledAccordion.Item
                    key={rest.id}
                    {...{
                      isAccordionItem: (subItems?.length ?? 0) > 0,
                      index,
                      HandleProps: {
                        cx: { p: 'md' },
                        onClick: () => {
                          setLoginNavActive(!loginNavActive);
                          setDrawer(drawer, rest.colorSet);
                        },
                      },
                      style: { border: 'none' },
                      LabelProps: {
                        children: rest.href ? (
                          <Btn variant="nav" onClick={handleClose} {...rest}>
                            <Txt as="h6" variant="h6" noMargin>
                              {rest.children}
                            </Txt>
                          </Btn>
                        ) : (
                          <Txt as="h6" variant="h6" noMargin>
                            {rest.children}
                          </Txt>
                        ),
                      },
                      BodyProps: {},
                      children: (
                        <Flex cx={{ pX: 'margin' }} spacing="xs" alignItems="start">
                          <NavList direction="column" spacing="2xs" items={subItems} />
                        </Flex>
                      ),
                    }}
                  />
                );
              }
            )}
          </UnstyledAccordion>
          <Flex cx={{ p: 'margin' }}>
            {ctaNav?.items?.map(
              ({ type, subItems, newWindow, external, parentId, href, ...rest }) => {
                return href ? (
                  <Btn key={rest.id} size="large" color="blue" href={href} {...rest} />
                ) : (
                  <Btn key={rest.id} size="large" color="blue" {...rest} />
                );
              }
            )}
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
}
